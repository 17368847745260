import React from 'react'
import styled from 'styled-components'

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;

  box-sizing: border-box;

  * {
    box-sizing: border-box;
  }
`

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;

  @media (max-width: 980px) {
    padding: 10px 0 20px;
  }
`

export const PolicyTitle = styled.h1`
  margin: 50px 0 10px;
  color: #2cb5ae;
  font-size: 36px;
`

export const PolicyIntro = styled.p`
  color: #191919;
  font-size: 16px;
  font-weight: 500;
`

export const UpdatedDate = styled.p`
  color: #262626;
  font-size: 14px;
  margin: 0 0 20px;
`

export const Date = styled.span`
  color: #2cb5ae;
`

export const PolicyParagraphTitle = styled.h2`
  width: 100%;
  text-align: left;
  /* padding-left: 40px; */
  color: #2cb5ae;
  margin-bottom: 10px;
`

export const PolicyParagraph = styled.p`
  width: 100%;
  margin-bottom: 10px;
  color: #191919;
  padding: 0 20px;
`

export const PolicyList = styled.ul`
  margin-bottom: 10px;
`

export const PrivacyPolicyPage = () => {
  return (
    <PageWrapper>
      <ContentWrapper>
        <PolicyTitle>Privacy Policy</PolicyTitle>
        <UpdatedDate>
          Last Updated: <Date>March 11 2024</Date>
        </UpdatedDate>
        <PolicyIntro>
          Welcome to PhantomKey, developed by 7cSolutions ("us", "we", or
          "our"). This Privacy Policy outlines how we handle information when
          you use PhantomKey.
        </PolicyIntro>
        {/*  */}
        <PolicyParagraphTitle>
          Information We Don't Collect
        </PolicyParagraphTitle>
        <PolicyParagraph>
          We want you to know that PhantomKey does not collect or store any
          personal information about you. We do not gather any personally
          identifiable data such as your name, email address, phone number, or
          any other information.
        </PolicyParagraph>
        <PolicyParagraphTitle>
          Use of Non-Personal Information
        </PolicyParagraphTitle>
        <PolicyParagraph>
          While you're using PhantomKey, we may collect non-personal information
          such as device information, usage statistics, and technical details.
          This information helps us understand how our app is being used and how
          we can improve it. However, this data is collected anonymously and
          cannot be used to identify you personally.
        </PolicyParagraph>
        <PolicyParagraphTitle>No Sharing of Information</PolicyParagraphTitle>
        <PolicyParagraph>
          Since we don't collect any personal information, we don't share any
          information with third parties.
        </PolicyParagraph>
        <PolicyParagraphTitle>Security</PolicyParagraphTitle>
        <PolicyParagraph>
          Although we don't store any user data, we still take security
          seriously. PhantomKey is designed to ensure your privacy and security
          while using our app.
        </PolicyParagraph>
        <PolicyParagraphTitle>
          Changes to This Privacy Policy
        </PolicyParagraphTitle>
        <PolicyParagraph>
          We reserve the right to update our Privacy Policy from time to time.
          Any changes will be reflected on this page, and we encourage you to
          review this Privacy Policy periodically for any updates.
        </PolicyParagraph>
        <PolicyParagraphTitle>Contact Us</PolicyParagraphTitle>
        <PolicyParagraph>
          If you have any questions or concerns about our Privacy Policy or the
          way we handle data, please contact us at info@7csolutions.com.
          <br />
          By using PhantomKey, you agree to the terms outlined in this Privacy
          Policy.
          <br />
          Thank you for choosing PhantomKey!
        </PolicyParagraph>
      </ContentWrapper>
    </PageWrapper>
  )
}
