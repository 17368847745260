import axios from 'axios'
import { action, makeAutoObservable, runInAction } from 'mobx'
import { docuRapidoTheme } from '../constants/docuRapidoTheme'

interface StepInterface {
  order: number
  completed: boolean
}

interface Document {
  email: string
  recipientName: string
  documentName: string
  CPF: string
  dateOfIssue: string
  deadline: string
  status: string
}

export class AppStateStore {
  rootStore
  authorization = false
  initalAuthCheck = false
  theme: any = docuRapidoTheme
  pageWidth: number = 0
  pageHeight: number = 0

  docsTableCurrentPageIndex: number = 0
  docsTableLimit: number = 10
  docsTableTotalPages: number = 10
  docsTablePageTokenArray: string[] = ['asdf', 'qwer', 'zxcv', '1234']

  constructor(rootStore) {
    makeAutoObservable(this)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.rootStore = rootStore
  }

  @action.bound setAuthorization(auth: boolean): void {
    this.authorization = auth
  }

  @action.bound setTheme(theme): void {
    this.theme = theme
  }

  @action.bound setPageWidth(width: number): void {
    this.pageWidth = width
  }

  @action.bound setPageHeight(height: number): void {
    this.pageHeight = height
  }

  @action
  async checkLogin() {
    try {
      const res = await axios(
        'https://merchant-poc-2577551f9ce2.herokuapp.com/auth/check',
        {
          method: 'GET',
          withCredentials: true,
        }
      )

      console.log(res)

      runInAction(() => {
        this.initalAuthCheck = true
        this.authorization = true
        this.rootStore.Router.setLocation(window.location.pathname.substring(1))
      })
    } catch (e) {
      runInAction(() => {
        this.initalAuthCheck = true
        this.authorization = true // set to "true" to skip authorization for local development
        this.rootStore.Router.setLocation(window.location.pathname.substring(1))
      })
      console.log(e)
    }
  }

  @action.bound setDocsTableCurrentPageIndex(index: number): void {
    this.docsTableCurrentPageIndex = index
    this.rootStore.CommunicationState.getDocumentList(
      this.docsTablePageTokenArray[index]
    )
  }
}
