import styled from 'styled-components'

interface ContainerProps {
  margin?: string
}

export const Container = styled.div<ContainerProps>`
  width: 100%;
  display: flex;
  font-family: 'Cabin';
  margin: ${(props) => (props.margin ? props.margin : '40px 0 32px')};

  @media (max-width: 980px) {
    flex-direction: column;
    align-items: center;
    gap: 32px;
  }
`

export const Headings = styled.div`
  width: 100%;
`

export const Toolbox = styled.div`
  @media (max-width: 980px) {
    width: 100%;
  }
`

export const Header = styled.h1`
  font-size: 26px;
  line-height: 36px;
  color: #1d24ca;
  margin: 0 0 4px 0;
  padding: 0;
  font-weight: 700;
`

export const Subtitle = styled.span`
  font-size: 14px;
  line-height: 20px;
  color: #201658;
`
