import { action, makeAutoObservable, runInAction } from 'mobx'

const routes = {
  login: {
    componentName: 'login',
    paths: ['login'],
    title: 'Docurapido - Please Login',
    secure: false,
  },
  documents: {
    componentName: 'documents',
    paths: ['documents', '/'],
    title: 'Docurapido - Documents',
    secure: true,
  },
  documentView: {
    componentName: 'documentView',
    paths: ['documents/view', 'view'],
    title: 'Docurapido - Document',
    secure: true,
  },
  documentSign: {
    componentName: 'documentSign',
    paths: ['document-sign'],
    title: 'Docurapido - Document Sign',
    secure: false,
  },
}

export function getPageNameByPath(path: string) {
  console.log('path', path)
  let output

  Object.values(routes).forEach((route, index) => {
    const foundPath = route.paths.find((setPath) => setPath == path)

    if (foundPath !== undefined) {
      output = Object.values(routes)[index]
    }
  })

  return output
}

export class RouterStateStore {
  rootStore
  currentPath = '/'
  location

  constructor(rootStore) {
    makeAutoObservable(this)
    this.rootStore = rootStore
    this.location = window.location
  }

  @action.bound setCurrentPath(path: string): void {
    this.currentPath = path
  }

  @action.bound setLocation(location: string): void {
    runInAction(() => {
      this.location = `${window.location.origin}/${location}`
      this.currentPath = location
    })

    history.pushState(
      {
        id: location,
      },
      location.toUpperCase(),
      `${window.location.origin}/${location}`
    )
  }
}
