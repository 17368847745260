export const documentsArrayMock = [
  {
    email: 'john@rosenthal.com',
    recipientName: 'John Rosenthal',
    documentName: 'Rosenthal co lorem ipsum lorem',
    CPF: '123.252.621.84',
    dateOfIssue: '24/12/2024',
    deadline: '01/02/2024',
    status: 'active',
  },
  {
    email: 'londoncityfox@gmail.com',
    recipientName: 'Moritz Wilhelm Cremer',
    documentName: 'Test co. lorem ipsum lorem',
    CPF: '716.732.391.88',
    dateOfIssue: '01/01/2024',
    deadline: '01/02/2024',
    status: 'pending',
  },
  {
    email: 'cleaning_au@proton.com',
    recipientName: 'Ray Shoesmith',
    documentName: 'TheMagician.pdf',
    CPF: '725.113.526.24',
    dateOfIssue: '12/12/2024',
    deadline: '24/01/2024',
    status: 'pending',
  },
  {
    email: 'john@rosenthal.com',
    recipientName: 'John Rosenthal',
    documentName: 'Rosenthal co lorem ipsum lorem',
    CPF: '123.252.621.84',
    dateOfIssue: '24/12/2024',
    deadline: '01/02/2024',
    status: 'active',
  },
  {
    email: 'londoncityfox@gmail.com',
    recipientName: 'Moritz Wilhelm Cremer',
    documentName: 'Test co. lorem ipsum lorem',
    CPF: '716.732.391.88',
    dateOfIssue: '01/01/2024',
    deadline: '01/02/2024',
    status: 'pending',
  },
  {
    email: 'cleaning_au@proton.com',
    recipientName: 'Ray Shoesmith',
    documentName: 'TheMagician.pdf',
    CPF: '725.113.526.24',
    dateOfIssue: '12/12/2024',
    deadline: '24/01/2024',
    status: 'pending',
  },
  {
    email: 'john@rosenthal.com',
    recipientName: 'John Rosenthal',
    documentName: 'Rosenthal co lorem ipsum lorem',
    CPF: '123.252.621.84',
    dateOfIssue: '24/12/2024',
    deadline: '01/02/2024',
    status: 'active',
  },
  {
    email: 'londoncityfox@gmail.com',
    recipientName: 'Moritz Wilhelm Cremer',
    documentName: 'Test co. lorem ipsum lorem',
    CPF: '716.732.391.88',
    dateOfIssue: '01/01/2024',
    deadline: '01/02/2024',
    status: 'pending',
  },
  {
    email: 'cleaning_au@proton.com',
    recipientName: 'Ray Shoesmith',
    documentName: 'TheMagician.pdf',
    CPF: '725.113.526.24',
    dateOfIssue: '12/12/2024',
    deadline: '24/01/2024',
    status: 'pending',
  },
  {
    email: 'john@rosenthal.com',
    recipientName: 'John Rosenthal',
    documentName: 'Rosenthal co lorem ipsum lorem',
    CPF: '123.252.621.84',
    dateOfIssue: '24/12/2024',
    deadline: '01/02/2024',
    status: 'active',
  },
  {
    email: 'londoncityfox@gmail.com',
    recipientName: 'Moritz Wilhelm Cremer',
    documentName: 'Test co. lorem ipsum lorem',
    CPF: '716.732.391.88',
    dateOfIssue: '01/01/2024',
    deadline: '01/02/2024',
    status: 'pending',
  },
  {
    email: 'cleaning_au@proton.com',
    recipientName: 'Ray Shoesmith',
    documentName: 'TheMagician.pdf',
    CPF: '725.113.526.24',
    dateOfIssue: '12/12/2024',
    deadline: '24/01/2024',
    status: 'pending',
  },
]
