export const miniTheme = {
  globals: {
    name: 'Mini',
    fontFamily: "'MINI Sans Serif', sans-serif;",
    fontFamilyHeadline: '"MINI Serif Headline"',
    bgColor: 'rgba(240, 240, 240, 1)',
    linkColor: 'rgba(0, 101, 131, 1)',
    linkColorHover: 'rgba(0, 125, 163, 1)',
    checkboxColor: 'rgba(0, 0, 0, 1)',
    checkboxColorActive: 'rgba(0, 0, 0, 1)',
  },
  container: {
    bgColor: '#FFFFFF',
    width: '100%',
    padding: '40px 60px 60px',
    borderRadius: '8px',
    border: '1px solid rgba(230, 230, 230, 1)',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;',
    margin: '0 auto',
    color: 'rgba(0, 0, 0, 1)',
  },
  documentCheckbox: {
    iconColor: 'rgba(0, 0, 0, 1)',
    iconHover: 'rgba(0, 0, 0, 1)',
    width: '100%',
    contentFontSize: '14px',
    margin: '20px',
  },
  icon: {
    color: 'rgba(255, 255, 255, 1)',
    colorHover: 'rgba(255, 255, 255, 1)',
    size: '32px',
    thickness: '0.2px',
  },
  otpInput: {
    width: '100%',
    columnGap: '10px',
    marginTop: '10px',
    marginBottom: '30px',
    inputHeight: '60px',
    inputBorder: '1px solid rgba(220, 220, 220, 1)',
    borderRadius: '0px',
    fontSize: '28px',
    focusColor: 'rgba(0, 101, 131, 1)',
    bgColor: 'rgba(250, 250, 250, 1)',
    focusBgColor: '#ffffff',
    fontColor: 'rgba(38, 38, 38, 1)',
    loadingColor: 'rgba(0, 101, 131, 1)',
  },
  pdfViewer: {
    pageBackground: 'rgba(255, 255, 255, 1)',
    canvasWidth: '495',
    containerPadding: '',
    containerBorder: '1px solid rgba(235, 235, 235, 1)',
    navigationTop: '',
    navigationLeft: '',
    navigationRight: '',
    navigationBottom: '',
    navigationBgColor: 'rgba(242, 242, 242, 1)',
    navigationBorderRadius: '0px',
    navigationPadding: '8px',
    navigationBoxShadow: '',
    navigationButtonBgColor: 'transparent',
    navigationButtonBorder: '1px solid rgba(110, 110, 110, 0.4)',
    navigationButtonColor: 'rgba(38, 38, 38, 1)',
    navigationButtonBgColorHover: 'rgba(0, 125, 163, 1)',
    navigationButtonBorderHover: '1px solid rgba(28, 105, 212, 1)',
    navigationButtonColorHover: 'rgba(255, 255, 255, 1)',
    navigationButtonPadding: '0px 5px',
    navigationButtonColorDisabled: 'rgba(149, 149, 149, 1)',
    navigationButtonBgColorDisabled: 'rgba(228, 228, 228, 1)',
    navigationButtonBorderDisabled: '1px solid rgba(230, 230, 230, 1)',
    navigationSpacerColor: 'rgba(180, 180, 180, 1)',
    searchTop: '',
    searchLeft: '',
    searchRight: '',
    searchBottom: '',
    searchBgColor: 'rgba(242, 242, 242, 1)',
    searchBorderRadius: '0px',
    searchPadding: '8px',
    searchBoxShadow: '',
    searchColor: 'rgba(38, 38, 38, 1)',
    searchFontSize: '',
    searchInputRadius: '0px',
    searchInputHeight: '',
    searchInputColor: '',
    searchInputBgColor: '',
    searchInputBgColorFocus: 'rgba(255, 255, 255, 1)',
    searchInputBorder: '1px solid rgba(210, 210, 210, 1)',
    searchInputFocusBorder: '1px solid rgba(210, 210, 210, 1)',
    searchInputFocusOutline: '2px solid rgba(28, 105, 212, 1)',
    searchInputPadding: '',
  },
  button: {
    bgColor: 'rgba(0, 101, 131, 1)',
    padding: '10px 24px',
    borderRadius: '56px',
    width: 'auto',
    border: '1px solid rgba(28, 105, 212, 1)',
    boxShadow: 'none',
    margin: '0',
    color: 'rgba(255, 255, 255, 1) !important',
    colorHover: 'rgba(255, 255, 255, 1) !important',
    bgColorHover: 'rgba(1, 125, 163, 1)',
    borderHover: '1px solid rgba(1, 125, 163, 1)',
    bgColorDisabled: 'rgba(215, 215, 215, 1.0)',
    borderDisabled: '1px solid rgba(215, 215, 215, 0.5)',
    colorDisabled: 'rgba(0, 0, 0, 1) !important',
    fontSize: '14px',
    fontWeight: '400',
  },
  buttonDisabled: {
    bgColor: 'rgba(215, 215, 215, 1.0)',
    padding: '10px 24px',
    borderRadius: '56px',
    width: 'auto',
    border: '1px solid rgba(215, 215, 215, 0.5)',
    boxShadow: 'none',
    margin: '0',
    color: 'rgba(0, 0, 0, 1) !important',
    colorHover: 'rgba(0, 0, 0, 1) !important',
    bgColorHover: 'rgba(215, 215, 215, 1.0)',
    borderHover: '1px solid rgba(215, 215, 215, 0.5)',
    fontSize: '14px',
    fontWeight: '400',
  },
  header: {
    color: 'rgba(0, 0, 0, 1)',
    fontSize: '1.625rem',
    lineHeight: '2.25rem',
    fontSizeMobile: '1.25rem',
    lineHeightMobile: '1.9375rem',
    margin: '15px 0',
    textAlign: 'center',
    width: '100%',
  },
  selectInput: {
    borderRadius: '0',
    borderWidth: '1px',
    primary: 'rgba(0, 101, 131, 1)',
    primary25: 'rgba(153, 223, 244, 1)',
    primary50: 'rgba(2, 184, 240, 1)',
    primary75: 'rgba(174, 228, 245, 1)',
    danger: 'rgba(226,64,40,1)',
    dangerLight: 'rgba(226,64,40,0.5)',
  },
  symbol: {
    primary: 'rgba(0, 101, 131, 1)',
    secondary: 'rgba(255, 255, 255, 1)',
    tenary: 'rgba(242, 242, 242, 1)',
  },
  textInput: {
    bgColor: 'white',
    padding: '14px',
    borderRadius: '0px',
    border: '1px solid rgba(220, 220, 220, 1)',
    boxShadow: 'none',
    margin: '0',
    color: 'gray',
    colorFocus: 'rgba(0, 0, 0, 1)',
    bgColorFocus: 'white',
    borderFocus: '1px solid rgba(0, 101, 131, 1)',
    bgColorDisabled: 'silver',
    borderDisabled: 'gray',
    colorDisabled: 'white',
    fontSize: '14px',
    fontWeight: '400',
    outline: '2px solid rgba(0, 101, 131, 1)',
    errorColor: '#d91111',
  },
  brandBar: {
    bgColor: 'white',
    padding: '30px',
    margin: '0 0 30px 0',
    border: 'none',
    boxShadow: 'none',
    justifyContent: 'normal',
  },
  wrapXs2a: {
    fontFamily: "'MINI Sans Serif', sans-serif;",
    fontFamilyHeadline: '"MINI Serif Headline"',
    bgColor: 'rgba(240, 240, 240, 1)',
    linkColor: 'rgba(0, 101, 131, 1)',
    linkColorHover: 'rgba(0, 125, 163, 1)',
    checkboxColor: 'rgba(0, 0, 0, 1)',
    checkboxColorActive: 'rgba(0, 0, 0, 1)',
    backButton: {
      borderColor: 'rgba(220, 220, 220, 1)',
      color: 'gray',
      colorHover: 'rgba(38, 38, 38, 1)',
      disabled: 'silver',
    },
    input: {
      bgColor: 'white',
      padding: '14px',
      borderRadius: '0px',
      border: '1px solid rgba(220, 220, 220, 1)',
      boxShadow: 'none',
      margin: '0',
      color: 'gray',
      colorFocus: 'rgba(0, 0, 0, 1)',
      bgColorFocus: 'white',
      borderFocus: '1px solid rgba(0, 101, 131, 1)',
      bgColorDisabled: 'silver',
      borderDisabled: 'gray',
      colorDisabled: 'white',
      fontSize: '14px',
      fontWeight: '400',
      outline: '2px solid rgba(0, 101, 131, 1)',
      errorColor: '#d91111',
    },
    button: {
      bgColor: 'rgba(0, 101, 131, 1)',
      padding: '14px 40px',
      borderRadius: '56px',
      width: 'auto',
      border: '1px solid rgba(28, 105, 212, 1)',
      boxShadow: 'none',
      margin: '0',
      color: 'rgba(255, 255, 255, 1) !important',
      colorHover: 'rgba(255, 255, 255, 1) !important',
      bgColorHover: 'rgba(1, 125, 163, 1)',
      borderHover: '1px solid rgba(1, 125, 163, 1)',
      bgColorDisabled: 'rgba(215, 215, 215, 0.5)',
      borderDisabled: '1px solid rgba(215, 215, 215, 0.5)',
      colorDisabled: 'rgba(0, 0, 0, 1)',
      fontSize: '14px',
      fontWeight: '400',
    },
  },
  wrapKlarna: {
    bgColor: 'rgba(240, 240, 240, 1)',
  },
  wrapKontomatik: {
    bgColor: '#FFFFFF',
    textColor: 'rgba(0, 0, 0, 1)',
    borderColor: 'rgba(28, 105, 212, 1)',
    headerColor: 'rgba(0, 0, 0, 1)',
    primaryColor: 'rgb(0, 101, 131)',
    primaryReverseColor: '#FFF',
    secondaryColor: '586374',
    mainHoverColor: 'rgb(1, 125, 163)',
    mainHoverReverseColor: '#FFF',
    listHoverColor: '#F5F5F5',
    disabledColor: '#CED4DA',
    errorColor: '#BB0000',
    width: '100%',
    padding: '40px 60px 60px',
    borderRadius: '8px',
    buttonBorderRadius: '56px',
    border: '1px solid rgba(230, 230, 230, 1)',
    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;',
    margin: '0 auto',
  },
  progressBar: {
    time: 180,
    secondChance: true,
    showCounter: true,
    height: '24px',
    margin: '40px 0 10px 0',
    padding: '0 20px',
    border: '1px solid rgba(220, 220, 220, 1)',
    borderRadius: '4px',
    background: 'white',
    barFill: 'rgba(0, 101, 131, 1)',
    counterMargin: '20px 0',
    counterPadding: '0',
    counterFontSize: '24px',
    counterColor: 'rgba(38, 38, 38, 1)',
    infoMessageTextAlign: 'center',
    infoMessageFontSize: '12px',
    infoMessageMargin: '6px 0 0 0',
  },
  stepper: {
    borderColor: 'rgba(67, 21, 128, 1)',
    fontSizeTitle: '12px',
    fontSizeDesc: '10px',
  },
  loadingSpinner: {
    primary: 'rgba(1, 125, 163, 1)',
    bgColor: 'rgba(240, 240, 240, 1)',
  },
  tabSwitcher: {
    borderBottom: '2px solid #dbdbdb',
    buttonColor: '#9b9b9b',
    buttonBorderBottom: '4px solid rgba(1, 125, 163, 1)',
    buttonColorActive: '#000',
  },
}
