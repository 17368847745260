import styled from 'styled-components'

export const InnerAppLayout = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  gap: 46px;
  padding: 36px 40px 0;

  @media (max-width: 980px) {
    padding: 36px 20px 0;
    gap: 0px;
  }
`

export const QRCodeWrapper = styled.div`
  margin: 50px 0;
`

export const FallbackButton = styled.button`
  outline: none;
  border: none;
  background: transparent;

  color: #2cb5ae;
  text-align: center;
  font-family: 'Futura LT';
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  text-decoration-line: underline;

  border-radius: 4px;
  font-size: 16px;
  padding: 8px 16px;
  cursor: pointer;
  transition: 0.3s;
`

export const InnerAppContent = styled.div<{ isMobile?: boolean }>`
  border-radius: 12px;
  border: ${(props) => (props.isMobile ? 'none' : '1px solid #dcdcdd')};
  background: #fff;

  width: 100%;
  box-sizing: border-box;
  padding: ${(props) => (props.isMobile ? '40px 0' : '50px 82px')};

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  max-width: 800px;

  @media (max-width: 980px) {
    /* padding: 10px 20px 20px; */
  }
`

export const BrowserViewContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* gap: 20px; */
`

export const MobileViewContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
`

export const PhantomKeyLogoWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Header = styled.h1`
  color: #2cb5ae;
  text-align: center;
  font-family: 'Futura LT';
  font-size: 36px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0 0 18px;
`

export const SubHeader = styled.h2`
  color: var(--Black, #000);
  text-align: center;
  font-family: 'Futura LT';
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  margin: 0;
`

export const OpenAppButton = styled.a`
  cursor: pointer;
  border-radius: 10px;
  /* background: linear-gradient(92deg, #30e2ff -18.88%, #9d2bff 120.58%); */
  /* background: #fcfdfd; */
  background-color: #1fbfb7;
  width: 100%;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  color: #fff;
  text-align: center;
  font-family: 'Futura LT';
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 0.78px;
  text-decoration: none;

  border: none;
  max-width: 350px;
  filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25));

  &:hover {
    transition: box-shadow 0.2s ease-out;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.25);
  }
`

export const CodeWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;

  text-align: center;
  font-family: 'Futura LT';
  font-size: 36px;
  font-style: normal;
  font-weight: 600;
  line-height: 40px;
  letter-spacing: 10px;
  color: #2cb5ae;

  padding: 40px 0;
`
