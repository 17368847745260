import React from 'react'
import {
  Container,
  Header,
  Headings,
  Subtitle,
  Toolbox,
} from './PageHeading.styles'

interface PageHeadingProps {
  title: string
  subtitle: string
  children?: React.ReactNode
  margin?: string
}

export const PageHeading = ({
  title,
  subtitle,
  children,
  margin,
}: PageHeadingProps) => {
  return (
    <Container margin={margin}>
      <Headings>
        <Header>{title}</Header>
        <Subtitle>{subtitle}</Subtitle>
      </Headings>
      <Toolbox>{children}</Toolbox>
    </Container>
  )
}
