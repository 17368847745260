import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { StoreContext } from '../../components/App'
import { DocumentsTable } from '../../components/DocumentsTable/DocumentsTable'
import { PageHeading } from '../../components/PageHeading/PageHeading'
import { InnerAppContent, InnerAppLayout } from '../../styles/generic.styles'

export const HomePage = observer(() => {
  const store = useContext(StoreContext)
  const { theme } = store.AppState

  return (
    <div>
      <InnerAppLayout>
        <InnerAppContent>
          <PageHeading
            title="Hello world!"
            subtitle="TPhantom Key link page"
          ></PageHeading>
        </InnerAppContent>
      </InnerAppLayout>
    </div>
  )
})
