export const docuRapidoTheme = {
  globals: {
    name: 'DocuRapido',
    fontFamily: '"Open Sans", sans-serif',
    fontFamilyHeadline: '"Cabin", sans-serif',
    bgColor: '#f0f7f6',
    linkColor: '#1D24CA',
    linkColorHover: '#201658',
    checkboxColor: '#1D24CA',
    checkboxColorActive: '#201658',
  },
  container: {
    bgColor: '#f0f7f6',
    width: '100%',
    padding: '14px 33px 21px',
    borderRadius: '4px',
    border: '1px solid #98ABEE',
    boxShadow: 'none',
    margin: '0 auto',
    color: '#383838',
  },
  documentCheckbox: {
    iconColor: '#1D24CA',
    iconHover: '#201658',
    width: '100%',
    contentFontSize: '14px',
    margin: '20px',
  },
  icon: {
    color: '#1D24CA',
    colorHover: '#1D24CA',
    size: '20px',
    thickness: '0.2px',
  },
  otpInput: {
    width: '100%',
    columnGap: '10px',
    marginTop: '10px',
    marginBottom: '30px',
    inputHeight: '60px',
    inputBorder: '2px solid #98ABEE',
    borderRadius: '4px',
    fontSize: '21px',
    focusColor: '#1D24CA',
    bgColor: '#ffffff',
    focusBgColor: '#ffffff',
    fontColor: '000000',
    loadingColor: '#1D24CA',
  },
  pdfViewer: {
    pageBackground: 'rgba(255, 255, 255, 1)',
    canvasWidth: '495',
    containerPadding: '',
    containerBorder: '1px solid rgba(235, 235, 235, 1)',
    navigationTop: '',
    navigationLeft: '',
    navigationRight: '',
    navigationBottom: '',
    navigationBgColor: 'rgba(202, 202, 202, 0.4)',
    navigationBorderRadius: '4px',
    navigationPadding: '8px',
    navigationBoxShadow:
      'rgba(0, 0, 0, 0.1) 0px 0px 5px 0px, rgba(0, 0, 0, 0.1) 0px 0px 1px 0px;',
    navigationButtonBgColor: '#1D24CA',
    navigationButtonBorder: '1px solid #1D24CA',
    navigationButtonColor: 'rgba(255, 255, 255, 1)',
    navigationButtonBgColorHover: '#201658',
    navigationButtonBorderHover: '1px solid #201658',
    navigationButtonColorHover: 'rgba(255, 255, 255, 1)',
    navigationButtonPadding: '0px 5px',
    navigationButtonColorDisabled: '#FBFBFB',
    navigationButtonBgColorDisabled: '#9FA0B8',
    navigationButtonBorderDisabled: '1px solid #9FA0B8',
    navigationSpacerColor: 'rgba(180, 180, 180, 1)',
    searchTop: '',
    searchLeft: '',
    searchRight: '',
    searchBottom: '',
    searchBgColor: 'rgba(202, 202, 202, 0.4)',
    searchBorderRadius: '4px',
    searchPadding: '8px',
    searchBoxShadow: 'none',
    searchColor: 'rgba(42, 0, 98, 1)',
    searchFontSize: '',
    searchInputRadius: '4px',
    searchInputHeight: '',
    searchInputColor: '',
    searchInputBgColor: '',
    searchInputBgColorFocus: 'rgba(255, 255, 255, 1)',
    searchInputBorder: '1px solid rgba(210, 210, 210, 1)',
    searchInputFocusBorder: '1px solid rgba(210, 210, 210, 1)',
    searchInputFocusOutline: '2px solid #1D24CA',
    searchInputPadding: '',
    scrollTrackColor: '#F5F1EA',
    scrollHandleColor: '#1D24CA',
    scrollRadius: '6px',
  },
  button: {
    bgColor: '1D24CA',
    padding: '12px 36px',
    borderRadius: '4px',
    width: 'auto',
    border: '1px solid #1D24CA',
    boxShadow: 'none',
    margin: '0',
    color: '#ffffff',
    colorHover: '#ffffff',
    bgColorHover: '#1F27ED',
    borderHover: '1px solid ##1F27ED',
    bgColorDisabled: '#9FA0B8',
    borderDisabled: '1px solid #9FA0B8',
    colorDisabled: '#FBFBFB',
    fontSize: '14px',
    fontWeight: '600',
  },
  buttonDisabled: {
    bgColor: '#9FA0B8',
    padding: '12px 36px',
    borderRadius: '4px',
    width: 'auto',
    border: '1px solid #9FA0B8',
    boxShadow: 'none',
    margin: '0',
    color: '#FBFBFB',
    colorHover: '#FBFBFB',
    bgColorHover: '#9FA0B8',
    borderHover: '1px solid #9FA0B8',
    fontSize: '14px',
    fontWeight: '600',
  },
  header: {
    color: '#1D24CA',
    fontSize: '26px',
    lineHeight: '36px',
    fontSizeMobile: '26px',
    lineHeightMobile: '36px',
    margin: '15px 0',
    textAlign: 'center',
    width: '100%',
  },
  selectInput: {
    borderRadius: '4',
    borderWidth: '2px',
    primary: 'rgba(29, 36, 202, 15)',
    primary25: 'rgba(29, 36, 202, 0.20)',
    primary50: 'rgba(29, 36, 202, 0.25)',
    primary75: 'rgba(29, 36, 202, 0.30)',
    danger: 'rgba(226,64,40,1)',
    dangerLight: 'rgba(226,64,40,0.5)',
  },
  symbol: {
    primary: 'rgba(29, 36, 202, 1)',
    secondary: 'rgba(255, 255, 255, 1)',
    tenary: 'rgba(242, 235, 224, 1)',
  },
  textInput: {
    bgColor: 'white',
    padding: '14px',
    borderRadius: '4px',
    border: '2px solid #98ABEE',
    boxShadow: 'none',
    margin: '8px 0 0 0',
    color: 'rgba(0, 0, 0, 1)',
    colorFocus: 'black',
    bgColorFocus: 'white',
    borderFocus: '2px solid #98ABEE',
    bgColorDisabled: '#9FA0B8',
    borderDisabled: '#9FA0B8',
    colorDisabled: '#FBFBFB',
    fontSize: '14px',
    fontWeight: '400',
    outline: '2px solid #1D24CA',
    errorColor: '#d91111',
  },
  brandBar: {
    bgColor: 'white',
    padding: '30px',
    margin: '0 0 30px 0',
    border: 'none',
    boxShadow: 'none',
    justifyContent: 'normal',
  },
  wrapXs2a: {
    fontFamily:
      '"Nunito Sans",-apple-system,".SFNSText-Regular","San Francisco",BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Helvetica,Arial,sans-serif',
    fontFamilyHeadline:
      '"Nunito Sans",-apple-system,".SFNSText-Regular","San Francisco",BlinkMacSystemFont,"Segoe UI","Helvetica Neue",Helvetica,Arial,sans-serif',
    bgColor: '#FFFFFF',
    linkColor: '#1D24CA',
    linkColorHover: '#1F27ED',
    checkboxColor: '#1D24CA',
    checkboxColorActive: '#1F27ED',
    backButton: {
      borderColor: 'rgba(65, 255, 209, 1)',
      color: '#505050',
      colorHover: '#505050',
      disabled: 'silver',
    },
    input: {
      bgColor: 'white',
      padding: '14px',
      borderRadius: '8px',
      border: '1px solid rgba(220, 220, 220, 1)',
      boxShadow: 'none',
      margin: '0',
      color: 'gray',
      colorFocus: 'black',
      bgColorFocus: 'white',
      borderFocus: '1px solid rgba(220, 220, 220, 1)',
      bgColorDisabled: 'silver',
      borderDisabled: 'gray',
      colorDisabled: 'white',
      fontSize: '14px',
      fontWeight: '400',
      outline: '2px solid rgba(65, 255, 209, 1)',
      errorColor: '#d91111',
    },
    button: {
      bgColor: 'rgba(65, 255, 209, 1)',
      padding: '12px 36px',
      borderRadius: '32px',
      width: 'auto',
      border: '1px solid rgba(65, 255, 209, 1)',
      boxShadow: 'none',
      margin: '0',
      color: 'rgba(42, 0, 98, 1)',
      colorHover: 'rgba(42, 0, 98, 1)',
      bgColorHover: 'rgba(65, 255, 209, 0.6)',
      borderHover: '1px solid rgba(65, 255, 209, 0.6)',
      bgColorDisabled: 'rgba(196, 253, 239, 1)',
      borderDisabled: '1px solid rgba(196, 253, 239, 1)',
      colorDisabled: 'rgba(78, 179, 153, 1)',
      fontSize: '13px',
      fontWeight: '600',
    },
  },
  wrapKlarna: {
    bgColor: '#FEFAF4',
  },
  wrapKontomatik: {
    bgColor: '#FEFAF4',
    textColor: '#FEFAF4',
    borderColor: '#98ABEE',
    headerColor: '#1D24CA',
    primaryColor: '#1D24CA',
    primaryReverseColor: '#201658',
    secondaryColor: '#7578CA',
    mainHoverColor: '#1F27ED',
    mainHoverReverseColor: '#868AE4',
    listHoverColor: '#FFF',
    disabledColor: '#9FA0B8',
    errorColor: '#BB0000',
    borderRadius: '4px',
    width: '100%',
    padding: '60px 80px 60px',
    boxShadow: 'none',
    margin: '0 auto',
  },
  progressBar: {
    time: 180,
    secondChance: true,
    showCounter: true,
    height: '32px',
    margin: '40px 0 10px 0',
    padding: '0 20px',
    border: '2px solid #98ABEE',
    borderRadius: '4px',
    background: 'white',
    barFill: '#1D24CA',
    counterMargin: '20px 0',
    counterPadding: '0',
    counterFontSize: '24px',
    counterColor: '#000000',
    infoMessageTextAlign: 'center',
    infoMessageFontSize: '12px',
    infoMessageMargin: '6px 0 0 0',
  },
  stepper: {
    borderColor: 'rgba(67, 21, 128, 1)',
    fontSizeTitle: '12px',
    fontSizeDesc: '10px',
  },
  loadingSpinner: {
    primary: '#1D24CA',
    bgColor: '#FFFFFF',
  },
  tabSwitcher: {
    borderBottom: '2px solid #dbdbdb',
    buttonColor: '#9b9b9b',
    buttonBorderBottom: '4px solid #2a0760',
    buttonColorActive: '#000',
  },
  topBar: {
    bgColor: '#1D24CA',
  },
  onboardingWizard: {
    color: '#767676',
    bgColor: '#FEFAF4',
    borderColor: '#98ABEE',
    colorActive: '#fff',
    bgColorActive: '#1D24CA',
    borderColorActive: '#1F27ED',
    colorDone: '#fff',
    bgColorDone: '#9FA0B8',
    borderColorDone: '#9FA0B8',
    colorStep: '#383838',
    colorStepActive: '#201658',
  },
  personCard: {
    nameColor: '#1D24CA',
    titleColor: '#201658',
    signatoryColor: '#1F27ED',
  },
  navMenu: {
    color: '#201658',
    colorActive: '#1F27ED',
    border: '6px solid #98ABEE',
    margin: '24px 0 40px 0',
  },
  webcamPhotoCapture: {
    borderRadius: '8px',
    selectStyles: {
      borderRadius: '2px',
      borderWidth: '2px',
      primary: 'rgba(206, 212, 220, 1)',
      primary25: 'rgba(206, 212, 220, 1)',
      primary50: 'rgba(206, 212, 220, 0.75)',
      primary75: 'rgba(206, 212, 220, 0.50)',
      danger: '#1D24CA',
      dangerLight: '#201658',
    },
    loadingSpinnerStyles: {
      primary: '#1D24CA',
      bgColor: '#FFFFFF',
    },
  },
  paginationButton: {
    borderRadius: '4px',
    fontFamily: '"Open Sans", sans-serif',
    color: 'rgb(255, 255, 255)',
    bgColor: '#1D24CA',
    bgColorHover: 'rgba(31, 39, 237, 0.9)',
    border: 'none',
    borderHover: 'none',
  },
  scrollBar: {
    trackColor: '#F5F1EA',
    handleColor: '#1D24CA',
    radius: '6px',
  },
}
