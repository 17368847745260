import { action, makeAutoObservable } from 'mobx'
import { documentsArrayMock } from '../constants/documentsMock'
import { axiosInstance } from '../methods/axiosConfig'

interface StepInterface {
  order: number
  completed: boolean
}

interface Document {
  email: string
  recipientName: string
  documentName: string
  CPF: string
  dateOfIssue: string
  deadline: string
  status: string
}

export class CommunicationStateStore {
  rootStore
  apiUrl = ''

  documentList: Document[] = []
  isDocumentListLoading = false

  constructor(rootStore) {
    makeAutoObservable(this)
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    this.rootStore = rootStore
  }

  @action.bound async getDocumentList(pageToken?: string) {
    this.isDocumentListLoading = true

    try {
      //const { data } = await axiosInstance.get(`${this.apiUrl}/documents`)

      this.documentList = documentsArrayMock
    } catch (error) {
      console.log(error)
    }

    this.isDocumentListLoading = false
  }
}
