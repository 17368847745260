import { Route, Switch } from 'wouter'
import { observer } from 'mobx-react'
import React, { useContext } from 'react'
import { HomePage } from '../pages/HomePage/HomePage'
import { UsePage } from '../pages/UsePage/UsePage'
import { CreatePage } from '../pages/CreatePage/CreatePage'
import { PrivacyPolicyPage } from '../pages/PrivacyPolicyPage'
import { ContentWrap } from '../styles/generic.styles'
import { StoreContext } from './App'

export const ContentRouter: React.FC = observer(() => {
  const store = useContext(StoreContext)

  return (
    <div className="content-router">
      <ContentWrap>
        <Switch>
          {/* <Route path="/login" component={LoginPage} /> */}

          <Route path="/" component={HomePage} />

          <Route path="/create/:code" component={CreatePage} />
          <Route path="/use/:code" component={UsePage} />

          <Route path="/privacy-policy" component={PrivacyPolicyPage} />
          {/* Default route in a switch */}
          <Route>
            <HomePage />
          </Route>
        </Switch>
      </ContentWrap>
    </div>
  )
})
